@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

p, hr {
  margin-bottom: 0;
  margin-top: 0;
}

a {
  text-decoration: underline;
  color: #182a39;
}

html, body {
  height: 100%;
  font-family: 'M PLUS 1p', sans-serif;
  color: #182a39;
}