.term-container {
    background-color: rgb(234, 234, 235);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
}

.term-container h1 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 80px;
}

.term-container .term-content-wrapper {
    display: flex;
    flex-direction: column;
    line-height: 32px;
}

.term-content-wrapper div{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.term-content-wrapper ul {
    list-style-type: disc;
}

.term-content-wrapper ul, .term-content-wrapper ol{
    padding-left: 20px;
}

.notice-content {
    width: 100%;
}

.notice-content-mobile {
    display: none;
    width: 100%;
}

ol > li::marker {
    color: rgb(218, 162, 116);
    font-weight: bold;
}
@media screen and (max-width: 992px) {
    .term-container {
        padding: 80px 24px;
    }
}

@media screen and (max-width: 576px) {
    .term-container {
        padding: 40px 16px;
    }
    .notice-content {
        display: none;
    }
    .notice-content-mobile {
        display: block;
    }
}